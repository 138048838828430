import { template as template_7a8d428a50ae463a9fa88ce322d6ad07 } from "@ember/template-compiler";
const FKText = template_7a8d428a50ae463a9fa88ce322d6ad07(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
