import { template as template_d7a72e73256144a3bd7f4ad20c413e57 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d7a72e73256144a3bd7f4ad20c413e57(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
