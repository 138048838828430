import { template as template_eb73fec72c514cc1a65dd3ecb8b0b8ac } from "@ember/template-compiler";
const WelcomeHeader = template_eb73fec72c514cc1a65dd3ecb8b0b8ac(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
