import { template as template_7fad7bf822964e83a958db79c07c7bde } from "@ember/template-compiler";
const SidebarSectionMessage = template_7fad7bf822964e83a958db79c07c7bde(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
