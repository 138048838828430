import { template as template_9f1f1dd47fe0496e81e8d07ad7e39bac } from "@ember/template-compiler";
const FKLabel = template_9f1f1dd47fe0496e81e8d07ad7e39bac(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
